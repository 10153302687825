<script>
    import S from 'string';
    import OverviewTable from '../Overview/Table';

    export default {
        extends: OverviewTable,

        methods: {
            rowGroupStartRender(rows, group) {
                const uniqueReasons = new Set(rows.data().toArray().flatMap(row => row.reasons.split('\n')));
                const totalAmount = rows.data().toArray().reduce((acc, row) => acc + row.amount, 0);
                const reasonsTitle = Array.from(uniqueReasons).join('\n');
                const title = `<strong>${S(group).escapeHTML().toString()}</strong> |
                    <abbr title="${S(reasonsTitle)}">${this.$tc('financial.values.number-of-reasons', uniqueReasons.size, { number: uniqueReasons.size })}</abbr> |
                    ${this.$tc('financial.values.number-of-appointments', rows.count(), { number: rows.count() })} |
                    ${this.$c(totalAmount)}`;

                return OverviewTable.methods.rowGroupStartRender.call(this, rows, title);
            },
        },
    };
</script>
