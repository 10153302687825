<template>
    <div :class="{'overview__table--no-headers': config.hideHeaders}" class="overview__table">
        <table ref="table" class="table" :class="{'table--scrollable': tableIsScrollable}">
            <thead>
                <tr>
                    <th v-if="config.checkable" class="table__checkbox-col">
                        <div class="table__th-wrapper">
                            <div class="form-checkbox overview-checkbox">
                                <label>
                                    <input
                                        :id="'frm_table_select_all_' + uniqueIdentifier"
                                        class="form-checkbox__input group-checkable"
                                        type="checkbox"
                                        value=""
                                        @click="toggleAll"
                                    >
                                    <span class="form-checkbox__label form-checkbox__label--no-content" />
                                </label>
                            </div>
                        </div>
                    </th>
                    <th v-if="config.subTables || config.groupsCollapsable" class="table__sub-table-col">
                        <span class="table__th-wrapper" />
                    </th>
                    <th v-for="column in config.columns" :key="column.name">
                        <span class="table__th-wrapper" :class="{'table__th-wrapper--icon': !!column.options.icon}">
                            <span v-if="column.options.icon"
                                  :aria-label="column.label"
                                  class="hint--left"
                            >
                                <frm-icon :name="column.options.icon" />
                            </span>
                            <template v-else>
                                {{ column.label }}
                            </template>
                        </span>
                    </th>
                    <th v-if="config.sortable" class="table__sortable-col">
                        <span class="table__th-wrapper" />
                    </th>
                    <th v-if="config.showContextMenuButton" class="table__context-menu-button-col">
                        <span class="table__th-wrapper" />
                    </th>
                </tr>
            </thead>
        </table>
    </div>
</template>

<script>
    import axios from 'axios';
    import S from 'string';
    import { mapGetters, mapMutations, mapState } from '../../store/helpers';

    import DefaultOverviewMixin from '../../mixins/Overview/Default';
    import HandlesShortcuts from '../../mixins/HandlesShortcuts';
    import HandlesActionRestrictions from '../../mixins/Overview/HandlesActionRestrictions';
    import HandlesActionVisibility from '../../mixins/Overview/HandlesActionVisibility';

    import OverviewTableColumnConfigBuilder from '../../services/OverviewTableColumnConfigBuilder';
    import OverviewTableSubTableRenderer from '../../services/OverviewTableSubTableRenderer';
    import buildContextMenuItemsFromActions, {
        removeFirstAndLastSeparator
    } from '../../services/buildContextMenuItemsFromActions';
    import dataGet from '../../services/dataGet';
    import debounce from '../../services/debounce';
    import elementIsInViewport from '../../services/elementIsInViewport';
    import iconRenderer from '../../services/iconRenderer';
    import ModalManager from '../../services/modalManager';

    import $ from 'jquery';
    import 'datatables.net-dt';
    import 'datatables.net-rowgroup-dt';
    import 'datatables.net-rowreorder-dt';
    import 'datatables.net-scroller-dt';
    import 'datatables.net-select-dt';
    import '../../plugins/datatables-current-filter';
    import { appendFilterData as appendDateFilterData } from '../../plugins/datatables-date-filter';
    import { appendFilterData as appendDateRangeFilterData } from '../../plugins/datatables-date-range-filter';
    import 'jquery-contextmenu';
    import 'jquery-ui/ui/position';
    import HandlesInstituteFilter from '../../mixins/Overview/HandlesInstituteFilter';
    import UrlQueryHelper from '../../services/UrlQueryHelper';

    $.fn.dataTable.ext.errMode = 'throw';

    export default {
        mixins: [DefaultOverviewMixin, HandlesShortcuts, HandlesActionRestrictions, HandlesActionVisibility, HandlesInstituteFilter],

        props: {
            config: {
                type: Object,
                required: true,
            },

            actionsDisabled: {
                type: Boolean,
                default: false,
            },

            handleAction: {
                type: Function,
                required: true,
            },

            scrollable: {
                type: Boolean,
                default: false,
            },

            rowHeight: {
                type: Number,
                default: 60,
            },
        },

        data() {
            return {
                dataTablesLoaded: false,
                doneInitializing: false,
                scrollPosition: 0,
                openedRows: [],
                openedSubtableRows: [],
            };
        },

        computed: {
            ...mapState([
                'fieldValueQuery',
                'searchQuery',
                'showFilters',
                'showOnlyActive',
                'showOnlyCurrent',
                'uniqueIdentifier',
                'selectedIds',
            ]),

            ...mapGetters([
                'activeFilterValues',
                'rowReorderDisabled',
            ]),

            dataTablesState: {
                get() {
                    return this.getFromState('dataTablesState');
                },
                set(value) {
                    this.setDataTablesState(value);
                },
            },

            tableIsScrollable() {
                return this.config.endlessScrollable || this.scrollable;
            },

            lockedRows() {
                return new Set(this.selectedIds.flatMap(id => this.getLinkedIds(id)));
            },
        },

        mounted() {
            if (this.config.endlessScrollable) {
                const style = document.createElement('style');
                style.id = `overview-${this.uniqueIdentifier}-style`;
                style.innerHTML = `#DataTables_Table_${this.uniqueIdentifier}_wrapper .dataTables_scrollBody {min-height: ${this.getScrollY()}px;}`;
                const ref = document.querySelector('script');
                ref.parentNode.insertBefore(style, ref);
            }

            // We wrap this in a setTimeout to make sure the debugbar is loaded and initialized first.
            window.setTimeout(() => {
                this.$options.$table = $(this.$refs.table);
                window.addEventListener('resize', debounce(() => {
                    this.handleResize();
                }, 25));
                this.init();
                this.initShortcuts();

                this.$watch('activeFilterValues', (newValue, oldValue) => {
                    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                        return;
                    }

                    Object.keys(this.activeFilterValues).forEach((attribute) => {
                        this.setFilter(attribute, this.activeFilterValues[attribute]);
                    });
                    this.deselectAndDraw();
                }, { deep: true });

                this.$watch('showOnlyActive', () => {
                    this.updateShowOnlyActive(this.showOnlyActive);
                    this.deselectAndDraw();
                });

                if (this.hasInstituteFilter) {
                    this.$watch('instituteId', () => {
                        this.refresh();
                    });
                }

                // set the showOnlyActive-value to the (default)value in the state
                if (this.config.showActiveFilter) {
                    this.updateShowOnlyActive(this.showOnlyActive);
                }

                this.$watch('showOnlyCurrent', () => {
                    // N.B. Filtering is done in DataTables plugin
                    this.deselectAndDraw();
                });

                this.$watch('fieldValueQuery', () => {
                    this.searchByFieldValue(this.fieldValueQuery);
                    this.deselectAndDraw();
                });

                this.$watch('searchQuery', () => {
                    if (this.searchQuery !== null) {
                        this.search(this.searchQuery);
                        this.deselectAndDraw();
                    }
                });

                this.$watch('rowReorderDisabled', () => {
                    if (this.rowReorderDisabled) {
                        this.disableRowReorder();
                    } else {
                        this.enableRowReorder();
                    }
                });

                ModalManager.$on(['beforeEnter', 'afterLeave'], () => {
                    requestAnimationFrame(() => {
                        this.handleResize();
                    });
                });

                if (this.config.groupingAction) {
                    $(this.$options.dataTable.table().body()).on('click', '.overview__column_group-wrapper-action', (e) => {
                        e.preventDefault();

                        this.$emit('groupingAction', $(e.target).data('grouping-action-value'));
                    });
                }

                if (this.config.linkedItems) {
                    this.$watch('lockedRows', () =>
                        Array.from(this.lockedRows)
                            .filter(id => !this.selectedIds.includes(id))
                            .forEach(id => this.selectRow(id, false)));

                    this.$watch('lockedRows', () =>
                        this.$options.dataTable.rows().every(rowIdx => {
                            const row = this.$options.dataTable.row(rowIdx);
                            const checkbox = row.node().querySelector('input[name="overview-active[]"]');

                            checkbox.disabled = this.lockedRows.has(row.id());
                        }));
                }

                this.doneInitializing = true;
                this.$options.dataTable.draw();
                this.handleResize();
            }, 0);
        },

        methods: {
            ...mapMutations([
                'resetFieldValueQuery',
                'setDataTablesState',
                'setFieldValueQuery',
                'setLoading',
            ]),

            deselectAndDraw() {
                this.deselectAllRows();
                this.$options.dataTable.draw();
            },

            getLinkedIds(sourceId) {
                return (this.$options.dataTable
                    .row(`#${sourceId}`)
                    .data()[this.config.linkedItems] || [])
                    .map(id => String(id));
            },

            toggleAll(event) {
                this.toggle(event.target.checked);
            },

            refresh(callback = null) {
                this.$emit('before-refresh');
                this.setLoading(true);

                if (this.$options.dataTable && !this.config.neverReload) {
                    this.$options.dataTable.ajax.reload((...args) => {
                        this.$emit('selectItems', {
                            ids: this.getSelectedIds(),
                            rowsData: this.getSelectedRowsData(),
                            readonlyIds: this.getReadOnlySelectedIds(),
                        });

                        if (callback !== null) {
                            callback(...args);
                        }
                    });
                }
            },

            selectRow(id, deselectFirst) {
                this.selectRowById(id, deselectFirst);
            },

            initShortcuts() {
                this.$shortcut.$on('overview.edit', () => {
                    if (!this.actionsDisabled && this.canHandleShortcuts()) {
                        this.$emit('editItem', { id: this.getSelectedIds() });
                    }
                });

                this.$shortcut.$on('overview.delete', () => {
                    if (!this.actionsDisabled && this.canHandleShortcuts()) {
                        this.$emit('deleteItem', { id: this.getSelectedIds() });
                    }
                });

                this.$shortcut.$on('overview.previous', (evt) => {
                    if (this.canHandleShortcuts()) {
                        evt.preventDefault();
                        this.selectPrev();
                    }
                });

                this.$shortcut.$on('overview.next', (evt) => {
                    if (this.canHandleShortcuts()) {
                        evt.preventDefault();
                        this.selectNext();
                    }
                });

                this.$shortcut.$on('overview.all', (evt) => {
                    if (this.canHandleShortcuts()) {
                        evt.preventDefault();
                        this.toggle(true);
                    }
                });
            },

            correctColumnIndex(index) {
                if (this.config.checkable) {
                    ++index;
                }

                if (this.config.subTables || this.config.groupsCollapsable) {
                    ++index;
                }

                return index;
            },

            init() {
                const dtOptions = {};
                dtOptions.ajax = {
                    url: this.config.url,
                    beforeSend: (jqXHR) => {
                        if (this.jqXHR) {
                            this.jqXHR.abort();
                        }

                        this.jqXHR = jqXHR;
                    },
                    data: (data, settings) => {
                        if (this.hasInstituteFilter) {
                            data[this.instituteFilter.attribute] = this.instituteId;
                        }

                        if (!settings.oInit.serverSide) {
                            return data;
                        }

                        appendDateFilterData(data, settings);
                        appendDateRangeFilterData(data, settings);

                        // Dealing with the endless scrollable in CI/CD environment is a pain, so limit the data to 99
                        if (location.hostname.startsWith('testing.') || localStorage.getItem('testing')) {
                            data.length = 99;
                        }

                        return data;
                    },
                    // We must use the dataFilter callback because this callback must be called before the success
                    // callback as we can't overwrite that without breaking DataTables.
                    dataFilter: (data) => {
                        this.dataTablesLoaded = true;

                        return data;
                    },
                };
                dtOptions.preDrawCallback = (settings) => {
                    // This is a nasty hack to make DataTables think it is the first draw until at least one AJAX call is
                    // successful. DataTables only shows the loader for the first draw. Our code somehow triggers
                    // multiple draws on init. This has to do with filtering, but can't easily be fixed.
                    if (!this.dataTablesLoaded) {
                        settings.iDraw = 0;
                    }

                    if (this.tableIsScrollable) {
                        this.scrollPosition = this.$options.$table.closest('.dataTables_scrollBody').scrollTop();
                    }

                    return this.doneInitializing;
                };
                dtOptions.drawCallback = () => {
                    if (this.config.groupsCollapsable) {
                        requestAnimationFrame(() => {
                            this.$options.dataTable.rows(this.openedRows.map(id => `:not(#${id})`).join(''))
                                .nodes()
                                .to$()
                                .hide();

                            if (this.openedRows.length) {
                                const $openedRows = this.$options.dataTable
                                    .rows(this.openedRows.map(id => `#${id}`).join(','))
                                    .nodes()
                                    .to$();
                                const $openedGroups = $openedRows.prev('tr.js-group');
                                const $buttons = $openedGroups.find('.js-overview-group-button');

                                $buttons.data('open', true);
                                $buttons.addClass('button--rotate-90');
                            }

                            this.$options.dataTable.columns.adjust();
                        });
                    }

                    // Reopen subtables
                    if (this.openedSubtableRows.length) {
                        requestAnimationFrame(() =>
                            this.$options.dataTable
                                .rows(this.openedSubtableRows.map(id => `#${id}`).join(','))
                                .nodes()
                                .to$()
                                .each((index, element) => $(element).find('.js-overview-sub-table-button').click()));
                    }

                    if (this.tableIsScrollable) {
                        requestAnimationFrame(() => {
                            this.$options.$table.closest('.dataTables_scrollBody').scrollTop(this.scrollPosition);
                        });
                    }
                };

                if (this.tableIsScrollable) {
                    dtOptions.scrollX = true;
                    dtOptions.scrollY = this.getScrollY();
                    dtOptions.scrollCollapse = true;
                }

                Object.assign(dtOptions, {
                    keys: true,
                    select: {
                        style: 'os',
                        className: 'active',
                    },
                    oCustomState: this.getFromState(),
                    oCustomSettings: this.config,
                    language: {
                        zeroRecords: this.$t('overview.clean-slate', null),
                        loadingRecords: `
                    <div class="spinner">
                        <div class="spinner__dot"></div>
                        <div class="spinner__dot"></div>
                        <div class="spinner__dot"></div>
                    </div>`,
                        processing: `
                    <div class="table__processing">
                        <div class="spinner">
                            <div class="spinner__dot"></div>
                            <div class="spinner__dot"></div>
                            <div class="spinner__dot"></div>
                        </div>
                    </div>`,
                    },
                    dom: this.config.endlessScrollable ? 't' : 'tr',
                    paging: false,
                    autoWidth: false,
                    columns: OverviewTableColumnConfigBuilder.build(this.config.columns, this.config),
                    order: [[this.correctColumnIndex(this.config.initialOrder), this.config.initialOrderDirection]],
                    ordering: this.config.orderableColumns === null || (Array.isArray(this.config.orderableColumns) && this.config.orderableColumns.length > 0),
                    rowId: 'id',
                    serverSide: this.config.serverSide,
                    processing: this.config.serverSide,
                    stateSave: true,
                    stateSaveCallback: (settings, data) => {
                        this.dataTablesState = data;
                    },
                    stateLoadCallback: () => {
                        return this.dataTablesState;
                    },
                    createdRow: this.createdRow.bind(this),
                });

                if (this.config.sortable) {
                    dtOptions.rowReorder = {
                        dataSrc: this.config.sortableColumn,
                        selector: 'tr td:last-child',
                        snapX: true,
                        update: false,
                    };

                    dtOptions.order = [this.config.columns.length + 1, 'asc'];
                    dtOptions.orderFixed = [this.config.columns.length + 1, 'asc'];

                    dtOptions.rowCallback = (row, data) => {
                        $(row).data('sorting-value', data[this.config.sortableColumn]);
                    };

                    if (this.rowReorderDisabled) {
                        this.$options.$table.one('init.dt', () => {
                            // this has to be in a timeout since we need the datatable
                            // to be rendered fully (and it's apparently not ready at the timing of this event)
                            setTimeout(() => {
                                this.disableRowReorder();
                            }, 100);
                        });
                    }
                }

                if (this.config.grouped) {
                    // Reset ordering
                    if (!this.config.serverSide) {
                        dtOptions.order = [];
                        dtOptions.orderFixed = [];
                    }

                    // Define grouping
                    dtOptions.rowGroup = {
                        className: `js-group ${this.config.groupsClassName}`.trim(),
                        startClassName: this.config.groupsClassName ? `${this.config.groupsClassName}-start` : '',
                        endClassName: this.config.groupsClassName ? `${this.config.groupsClassName}-end` : '',
                        startRender: this.rowGroupStartRender.bind(this),
                        dataSrc: this.config.groupingName,
                    };
                }

                this.$options.$table.on('init.dt', () => {
                    if (!window.location.hash || window.location.hash.substr(0, 5) !== '#row_') {
                        return;
                    }

                    const selectedRows = window.location.hash.substr(5).split(',');

                    // this has to be in a timeout since we need the datatable
                    // to be rendered fully (and it's apparently not ready at the timing of this event)
                    setTimeout(() => {
                        selectedRows.forEach(selectedRow => {
                            this.selectRowById(selectedRow, false);
                        });
                    }, 100);
                });

                if (this.config.endlessScrollable) {
                    dtOptions.deferRender = true;
                    dtOptions.paging = true;
                    dtOptions.scrollCollapse = true;
                    dtOptions.scrollY = () => this.getScrollY();
                    dtOptions.scroller = {
                        loadingIndicator: true,
                        displayBuffer: 7,
                        rowHeight: this.rowHeight,
                    };

                    window.addEventListener('resize', debounce(() => {
                        this.updateScrollY();
                    }, 25));

                    // initially show the loader
                    $(document).on('preInit.dt', () => {
                        this.$options.$table.closest('.dts').find('.dts_loading').css('display', 'block');
                    });
                    // and hide the loader and reset the min-height hack
                    this.$options.$table.on('init.dt', () => {
                        this.$options.$table.closest('.dts').find('.dts_loading').css('display', 'none');
                        document.getElementById(`overview-${this.uniqueIdentifier}-style`).remove();
                    });
                }

                this.$options.dataTable = this.$options.$table.DataTable(dtOptions);

                this.$options.dataTable.on('draw.dt', (e) => {
                    this.$emit('draw');
                });

                this.$options.dataTable.on('xhr.dt', (e, settings, json, xhr) => {
                    this.setLoading(false);

                    if (Number(xhr.status) >= 400) {
                        this.$emit('xhrError', xhr.responseJSON ? xhr.responseJSON.message : null);

                        // when returning true datatable does not throw its own error
                        return true;
                    }

                    this.$emit('xhr');

                    if (settings.iDraw > 1) {
                        this.$emit('update');
                    }
                });

                if (dtOptions.processing && !this.config.endlessScrollable) {
                    this.$options.dataTable.on('processing.dt', (e, settings, processing) => {
                        if (processing) {
                            requestAnimationFrame(() => {
                                this.$options.$table.css('display', 'none');
                                this.$options.$table.closest('.dataTables_wrapper').find('.dataTables_processing').css('display', 'block');
                            });
                        } else {
                            this.$options.$table.css('display', '');
                            this.$options.dataTable.columns.adjust();
                        }
                    });
                }

                // prevent double-clicking checkbox opening record
                $(this.$options.dataTable.table().body()).on('dblclick.dtSelect', '.overview__column--checkbox', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                });

                if (this.config.linkedItems) {
                    this.$options.dataTable.on('user-select', (e, dt, type, cell) => {
                        if (this.lockedRows.has(dt.row(cell.index().row).id())) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    });
                }

                $(this.$options.dataTable.table().body()).on('click.dtSelect', '.overview__column--checkbox', (e) => {
                    e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();

                    const $rowElement = $(e.currentTarget).closest('tr');

                    if (this.config.linkedItems && this.lockedRows.has(this.$options.dataTable.row($rowElement)?.id())) {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }

                    if ($rowElement.is('.js-group')) {
                        const dataTableRows = this.$options.dataTable.rows($rowElement.nextUntil('tr.js-group'));

                        if ($(e.currentTarget).find('input[type="checkbox"]').is(':checked')) {
                            dataTableRows.deselect();
                        } else {
                            dataTableRows.select();
                        }
                    } else {
                        const row = this.$options.dataTable.row($rowElement);

                        if ($rowElement.is('.active')) {
                            row.deselect();
                        } else {
                            row.select();
                        }
                    }
                });

                $(this.$options.dataTable.table().body()).on('click.dtSelect', '.js-overview-sub-table-button', (e) => {
                    e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();

                    const $button = $(e.currentTarget);
                    const $rowElement = $button.closest('tr');
                    const row = this.$options.dataTable.row($rowElement);

                    if (!row.child()) {
                        const columnCount = this.$options.dataTable.columns(':visible')[0].length;
                        const evenOddClass = $rowElement.is('.even') ? 'even' : 'odd';
                        const subTableData = dataGet(row.data(), this.config.subTableColumn, []);

                        if (this.config.subTableUrl) {
                            row.child(
                                [
                                    this.getSubTableSpacerRow(columnCount, evenOddClass),
                                    this.getSubTableRow(columnCount, evenOddClass, row.id(), this.config.subTableColumns, []),
                                    this.getSubTableLoadingRow(columnCount, evenOddClass),
                                    this.getSubTableSpacerRow(columnCount, evenOddClass),
                                ]
                            );
                            this.handleResize();

                            let url = window.decodeURIComponent(this.config.subTableUrl);

                            // Add selected id to the url
                            url = url.replace(':id', row.id());

                            // Add row data to the url
                            const data = row.data();
                            Object.keys(data)
                                // Sort the keys descending by length to avoid conflicts e.g. ':data.indication_history_id' being replaced with ':data.indication'
                                .sort((a, b) => b.length - a.length)
                                .forEach((key) => {
                                    url = url.replace(':data.' + key, data[key]);
                                });

                            // Add filters to the url
                            Object.keys(this.activeFilterValues)
                                // Sort the keys descending by length to avoid conflicts e.g. ':filter.indication_history_id' being replaced with ':filter.indication'
                                .sort((a, b) => b.length - a.length)
                                .forEach((key) => {
                                    url = url.replace(':filter.' + key, this.activeFilterValues[key]);
                                });

                            // Add state info to the url
                            url = url.replace(':state.fieldValueQuery', this.fieldValueQuery ? this.fieldValueQuery.field + ',' + this.fieldValueQuery.value : '');
                            url = url.replace(':state.searchQuery', this.searchQuery);
                            url = url.replace(':state.showOnlyActive', this.showOnlyActive);
                            url = url.replace(':state.showOnlyCurrent', this.showOnlyCurrent);

                            axios.get(url)
                                .then(response => {
                                    if (response.data.data && response.data.data.length > 0) {
                                        row.child(
                                            [
                                                this.getSubTableSpacerRow(columnCount, evenOddClass),
                                                this.getSubTableRow(columnCount, evenOddClass, row.id(), this.config.subTableColumns, response.data.data),
                                                this.getSubTableSpacerRow(columnCount, evenOddClass),
                                            ]
                                        );
                                        this.handleResize();
                                    } else {
                                        row.child(
                                            [
                                                this.getSubTableSpacerRow(columnCount, evenOddClass),
                                                this.getSubTableRow(columnCount, evenOddClass, row.id(), this.config.subTableColumns, []),
                                                this.getSubTableZeroRecordsRow(columnCount, evenOddClass),
                                                this.getSubTableSpacerRow(columnCount, evenOddClass),
                                            ]
                                        );
                                        this.handleResize();
                                    }
                                })
                                .catch(error => {
                                    // eslint-disable-next-line no-console
                                    console.error(error);
                                });
                        } else {
                            row.child(
                                [
                                    this.getSubTableSpacerRow(columnCount, evenOddClass),
                                    this.getSubTableRow(columnCount, evenOddClass, row.id(), this.config.subTableColumns, subTableData),
                                    this.getSubTableSpacerRow(columnCount, evenOddClass),
                                ]
                            );
                            this.handleResize();
                        }
                    }

                    if (row.child.isShown()) {
                        row.child.hide();
                        $button.removeClass('button--rotate-90');
                        this.openedSubtableRows = this.openedSubtableRows.filter(id => id !== row.id());
                    } else {
                        row.child.show();
                        $button.addClass('button--rotate-90');
                        this.openedSubtableRows.push(row.id());
                    }

                    this.handleResize();
                });

                this.$options.$table.on('click', '.js-overview-group-button', (e) => {
                    const $button = $(e.currentTarget);
                    const $rows = $button.closest('tr').nextUntil('tr.js-group');
                    const ids = this.$options.dataTable.rows($rows).ids().toArray();
                    const isOpen = $button.data('open');

                    if (isOpen) {
                        $rows.hide();
                        this.openedRows = this.openedRows.filter(id => !ids.includes(id));
                        $button.data('open', false);
                        $button.removeClass('button--rotate-90');
                    } else {
                        $rows.show();
                        this.openedRows = [...new Set(this.openedRows.concat(ids))];
                        $button.data('open', true);
                        $button.addClass('button--rotate-90');
                    }

                    this.$options.dataTable.columns.adjust();
                });

                this.$options.$table.on('dblclick', '.js-overview-sub-table-button,[data-no-select]', (e) => {
                    e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                });

                this.$options.$table.on('dblclick', '> tbody > tr:not(.js-group):not(.sub-table)', (e) => {
                    const $row = $(e.currentTarget);
                    const row = this.$options.dataTable.row($row);

                    // Select the row since otherwise it'll be deselected (second click toggles the row back to deselected state)
                    row.select();

                    if (this.actionsDisabled) {
                        return;
                    }

                    this.$emit('editPreferredItem', { id: row.data().id });
                });

                this.$options.$table.on('click', '[data-cell-action]', e => {
                    e.preventDefault();

                    // First, only select the row in order to pass data in the action
                    this.deselectAllRows();
                    this.$options.dataTable.row($(e.currentTarget).closest('tr')).select();

                    this.handleAction(this.$options.dataTable.cell($(e.currentTarget).closest('td')).data().action, e);
                });

                this.$options.dataTable.on('deselect', (e, dt, type, indexes) => {
                    if (type !== 'row') {
                        return;
                    }

                    this.$options.dataTable
                        .rows(indexes)
                        .nodes()
                        .to$()
                        .find('input[name="overview-active[]"]')
                        .prop('checked', false);

                    this.$emit('selectItems', {
                        ids: this.getSelectedIds(),
                        rowsData: this.getSelectedRowsData(),
                        readonlyIds: this.getReadOnlySelectedIds(),
                    });
                });

                this.$options.dataTable.on('select', (e, dt, type, indexes) => {
                    if (type !== 'row') {
                        return;
                    }

                    this.$options.dataTable
                        .rows(indexes)
                        .nodes()
                        .to$()
                        .find('input[name="overview-active[]"]')
                        .prop('checked', true);

                    this.$emit('selectItems', {
                        ids: this.getSelectedIds(),
                        rowsData: this.getSelectedRowsData(),
                        readonlyIds: this.getReadOnlySelectedIds(),
                    });
                });

                if (this.config.grouped && this.config.groupsCheckable) {
                    this.$options.dataTable.on('select deselect', () => {
                        this.$options.$table.find('tr.js-group').each((index, element) => {
                            const $tr = $(element);
                            const $checkbox = $tr.find('input[type="checkbox"]');
                            const rowStatuses = $tr.nextUntil('tr.js-group').get()
                                                    .map(row => row.classList.contains('active'));

                            if (rowStatuses.every(Boolean)) {
                                $tr.addClass('active');
                                $checkbox.prop('indeterminate', false).prop('checked', true);
                            } else if (rowStatuses.some(Boolean)) {
                                $tr.addClass('active');
                                $checkbox.prop('indeterminate', true).prop('checked', true);
                            } else {
                                $tr.removeClass('active');
                                $checkbox.prop('indeterminate', false).prop('checked', false);
                            }
                        });
                    });
                }

                if (this.config.sortable) {
                    // select the row before sorting, so we know which row was reordered originally
                    this.$options.$table.on('mousedown', '.overview__column--sorting', (e) => {
                        const $row = $(e.currentTarget).closest('tr');
                        const row = this.$options.dataTable.row($row);
                        this.deselectAllRows();
                        row.select();
                    });

                    this.$options.dataTable.on('row-reorder', (event, affectedRows) => {
                        // retrieve which row was reordered originally
                        const selected = this.$options.dataTable.rows({ selected: true });
                        if (selected.nodes().length !== 1) {
                            return;
                        }
                        const selectedData = selected.data()[0];

                        // loop thru the affectedrows and set the new position-value for each row.
                        const id = selectedData.id;
                        let newPositionForSelectedRow = null;
                        const extraProperties = {};
                        affectedRows.forEach((item) => {
                            const row = this.$options.dataTable.row(item.node);
                            const data = row.data();
                            data[this.config.sortableColumn] = item.newData;
                            row.data(data);

                            if (data.id === id) {
                                newPositionForSelectedRow = Number(item.newData);

                                if (this.config.groupingValue) {
                                    const previousRow = $(item.node).prev();
                                    if (previousRow) {
                                        extraProperties[this.config.groupingValue] = previousRow.data('grouping-value');
                                    }
                                }
                            }
                        });

                        // If we didn't find a new position because DataTables didn't see a change - this can be
                        // the case when dragging the first row of a group to the last position of the group
                        // above it - and groupingValue is enabled, we will try to find the diff ourselves.
                        if (!newPositionForSelectedRow && this.config.groupingValue) {
                            const previousRow = $(selected.nodes()[0]).prev();
                            if (previousRow) {
                                newPositionForSelectedRow = Number(previousRow.data('sorting-value')) + 1;
                                extraProperties[this.config.groupingValue] = previousRow.data('grouping-value');

                                // Check if anything has actually changed and reset if not
                                if (Number(selectedData[this.config.sortableColumn]) === newPositionForSelectedRow &&
                                    selectedData[this.config.groupingValue] === extraProperties[this.config.groupingValue]
                                ) {
                                    newPositionForSelectedRow = null;
                                    delete extraProperties[this.config.groupingValue];
                                }
                            }
                        }

                        if (newPositionForSelectedRow) {
                            this.$emit('reorderItem', { id, position: newPositionForSelectedRow, extraProperties });
                            this.selectRow(id, false);
                        } else {
                            this.$options.dataTable.draw();
                        }
                    });
                }

                const self = this;
                const isSubTableRow = elem => {
                    const row = $(elem).closest('tr');

                    return !!row.data('is_subtable_row');
                };

                const isGroupingRow = elem => {
                    const row = $(elem).closest('tr');

                    return !!row.data('is_grouping_row');
                };

                // Right click on the row
                this.$options.$table.contextMenu({
                    selector: '> tbody > tr td:not(.overview__column--actions-button):not(.table__sub-table)',
                    build($triggerElement) {
                        const row = $triggerElement.closest('tr');
                        const subtableRow = isSubTableRow(row);
                        const groupingRow = isGroupingRow(row);

                        const rowData = subtableRow
                            ? row.data('subtable_row_data')
                            : groupingRow
                                ? row.data('grouping_row_data')
                                : self.$options.dataTable.row(row).data();

                        let actions = subtableRow
                            ? self.config.subTableContextMenuItems
                            : groupingRow
                                ? self.config.groupingContextMenuItems
                                : self.config.contextMenuItems;

                        if (!actions.length && rowData && rowData.context_menu_items && rowData.context_menu_items.data) {
                            actions = rowData.context_menu_items.data;
                        }

                        const contextMenuItems = self.buildContextMenuItems(actions);

                        if (Object.keys(contextMenuItems).length === 0) {
                            return false;
                        }

                        return {
                            className: 'contextmenu_' + self.uniqueIdentifier,
                            zIndex: 10,
                            events: {
                                activated: options => removeFirstAndLastSeparator(options.$menu),
                                show(options) {
                                    if (isSubTableRow(this)) {
                                        self.$emit('selectItems', {
                                            ids: ['subrow'],
                                            rowsData: [this.closest('tr').data('subtable_row_data')],
                                            readonlyIds: [],
                                        });

                                        return;
                                    }

                                    if (isGroupingRow(this)) {
                                        self.$emit('selectItems', {
                                            ids: ['grouping'],
                                            rowsData: [this.closest('tr').data('grouping_row_data')],
                                            readonlyIds: [],
                                        });

                                        return;
                                    }

                                    const clickedRow = self.$options.dataTable.row($(this));
                                    if (clickedRow.data().readonly !== true && !$(clickedRow.node()).is('.active')) {
                                        self.deselectAllRows();
                                        clickedRow.select();
                                    }
                                },
                            },
                            items: contextMenuItems,
                        };
                    },
                });

                // Left click on the contextMenuButton
                if (this.config.showContextMenuButton) {
                    this.$options.$table.contextMenu({
                        selector: '.js-overview-context-menu-button',
                        trigger: 'left',
                        build($triggerElement) {
                            const row = $triggerElement.closest('tr');
                            const subtableRow = isSubTableRow(row);
                            const groupingRow = isGroupingRow(row);

                            const rowData = subtableRow
                                ? row.data('subtable_row_data')
                                : groupingRow
                                    ? row.data('grouping_row_data')
                                    : self.$options.dataTable.row(row).data();

                            let actions = subtableRow
                                ? self.config.subTableContextMenuItems
                                : groupingRow
                                    ? self.config.groupingContextMenuItems
                                    : self.config.contextMenuItems;

                            if (!actions.length && rowData.context_menu_items && rowData.context_menu_items.data) {
                                actions = rowData.context_menu_items.data;
                            }

                            const contextMenuItems = self.buildContextMenuItems(actions);

                            if (Object.keys(contextMenuItems).length === 0) {
                                return false;
                            }

                            return {
                                className: 'button_contextmenu_' + self.uniqueIdentifier,
                                zIndex: 10,
                                events: {
                                    activated: options => removeFirstAndLastSeparator(options.$menu),
                                    show(options) {
                                        if (isSubTableRow(this)) {
                                            self.$emit('selectItems', {
                                                ids: ['subrow'],
                                                rowsData: [this.closest('tr').data('subtable_row_data')],
                                                readonlyIds: [],
                                            });

                                            return;
                                        }

                                        if (isGroupingRow(this)) {
                                            self.$emit('selectItems', {
                                                ids: ['grouping'],
                                                rowsData: [this.closest('tr').data('grouping_row_data')],
                                                readonlyIds: [],
                                            });

                                            return;
                                        }

                                        const clickedRow = self.$options.dataTable.row($(this).closest('tr'));
                                        self.deselectAllRows();
                                        clickedRow.select();
                                    },
                                },
                                items: contextMenuItems,
                            };
                        },
                    });
                }
            },

            getSubTableSpacerRow(columnCount, className) {
                const tr = document.createElement('tr');
                tr.className = 'sub-table ' + className;

                for (let i = 0; i < columnCount; ++i) {
                    const td = document.createElement('td');
                    td.className = 'table__sub-table-spacer overview__column';

                    if (this.config.checkable) {
                        if (i === 0) {
                            td.className += ' overview__column--checkbox';
                        }
                        if (this.config.subTables && i === 1) {
                            td.className += ' overview__column--sub-table';
                        }
                    } else if (this.config.subTables) {
                        if (i === 0) {
                            td.className += ' overview__column--sub-table';
                        }
                    }

                    tr.appendChild(td);
                }

                return tr;
            },

            getSubTableLoadingRow(columnCount, className) {
                const tr = document.createElement('tr');
                tr.className = 'sub-table ' + className;

                const td = document.createElement('td');
                td.className = 'table__sub-table';
                td.colSpan = columnCount;
                tr.appendChild(td);

                const div = document.createElement('div');
                div.className = 'table__sub-table-processing';
                div.innerHTML = this.$options.dataTable.i18n('loadingRecords', 'Loading...');
                td.appendChild(div);

                return tr;
            },

            getSubTableZeroRecordsRow(columnCount, className) {
                const tr = document.createElement('tr');
                tr.className = 'sub-table ' + className;

                const td = document.createElement('td');
                td.className = 'table__sub-table';
                td.colSpan = columnCount;
                tr.appendChild(td);

                const div = document.createElement('div');
                div.className = 'table__sub-table-processing';
                div.innerHTML = this.$options.dataTable.i18n('zeroRecords', 'No data available in table');
                td.appendChild(div);

                return tr;
            },

            getSubTableRow(columnCount, className, id, columns, data) {
                const tr = document.createElement('tr');
                tr.className = 'sub-table ' + className;
                tr.id = 'subtable-' + id;

                const td = document.createElement('td');
                td.className = 'table__sub-table';
                td.colSpan = columnCount;
                tr.appendChild(td);

                td.appendChild(OverviewTableSubTableRenderer.render(columns, data, this.config.subTableShowContextMenuButton));

                return tr;
            },

            enableRowReorder() {
                if (this.config.sortable) {
                    this.$options.dataTable.rowReorder.enable();
                    this.$options.$table.removeClass('table--sortable-disabled');
                }
            },

            disableRowReorder() {
                if (this.config.sortable) {
                    this.$options.dataTable.rowReorder.disable();
                    this.$options.$table.addClass('table--sortable-disabled');
                }
            },

            search(query) {
                this.$options.dataTable.search(query);
            },

            buildContextMenuItems(actions) {
                return buildContextMenuItemsFromActions(actions, (action, element, key, opt, event) => {
                    this.handleAction(action, event.originalEvent);
                }, (action, element, key, opt) => {
                    return this.actionsDisabled || this.isActionDisabled(action, element);
                }, (action, element, key, opt) => {
                    return this.actionIsVisible(action, this.getRowsDataFromElement(element));
                });
            },

            getRowsDataFromElement(element) {
                // element could be a button
                element = element.closest('tr');

                if (element.data('is_subtable_row')) {
                    return [element.data('subtable_row_data')];
                }

                if (element.data('is_grouping_row')) {
                    return [element.data('grouping_row_data')];
                }

                let rowsData = [];
                const clickedRow = this.$options.dataTable.row($(element));

                if ($(clickedRow.node()).is('.active')) {
                    const selected = this.$options.dataTable.rows({ selected: true });
                    selected.every(rowIdx => {
                        const data = this.$options.dataTable.row(rowIdx).data();

                        rowsData.push(data);
                    });
                } else {
                    if (clickedRow.data()) {
                        const data = clickedRow.data();

                        rowsData = [data];
                    }
                }

                return rowsData;
            },

            isActionDisabled(action, element) {
                return this.actionIsRestricted(action, this.getRowsDataFromElement(element));
            },

            getReadOnlySelectedIds() {
                const selected = this.$options.dataTable.rows({ selected: true });
                const ids = [];
                selected.every((rowIdx) => {
                    const rowdata = this.$options.dataTable.row(rowIdx).data();

                    if (rowdata.readonly === true) {
                        ids.push(rowdata.id);
                    }
                });

                return ids;
            },

            getScrollY() {
                let subtract = this.config.hideHeaders ? 0 : 50;

                const actions = $(`#overview-actions_${this.uniqueIdentifier}`);
                if (actions.length) {
                    subtract += actions.outerHeight();
                }

                let bottomOffset = new UrlQueryHelper().isModal() ? 25 : 55;

                const $stickyButtonGroup = $('.form-button-group--sticky');
                if ($stickyButtonGroup.length) {
                    bottomOffset += $stickyButtonGroup.outerHeight();
                }

                const height = typeof this.config.height === 'function'
                    ? this.config.height()
                    // FIXME: Change this to some kind of parent lookup
                    : $(window).height() - $(`#${this.$attrs.id}`).offset().top - bottomOffset;

                return height - subtract;
            },

            updateScrollY() {
                if (!this.tableIsScrollable) {
                    return;
                }

                $(`#${this.$attrs.id}`).find('.dataTables_scrollBody').css('max-height', Math.max(180, this.getScrollY()));
            },

            getSelectedIds() {
                return this.getSelectedRowsData().map(data => data.id);
            },

            getSelectedRowsData() {
                const selected = this.$options.dataTable.rows({ selected: true });
                const data = [];
                selected.every((rowIdx) => {
                    data.push(this.$options.dataTable.row(rowIdx).data());
                });

                return data;
            },

            toggle(on) {
                if (on) {
                    this.$options.dataTable.rows({ search: 'applied' }).select();
                } else {
                    this.$options.dataTable.rows().deselect();
                }
            },

            selectRowById(id, deselectFirst = true) {
                const row = this.$options.dataTable.row('#' + id);

                if (!row.node() || !$(row.node()).is(':visible')) {
                    // row with this id does not exist or is not visible
                    return;
                }

                if (deselectFirst) {
                    this.deselectAllRows();
                }

                // select the element (it's checked)
                row.select();

                // if the element is not in the viewport, scroll to the element
                if (!elementIsInViewport(row.node())) {
                    row.node().scrollIntoView({ block: 'center' });
                }
            },

            setFilter(attribute, value) {
                const filter = this.config.filters.find(f => f.attribute === attribute);

                if (filter && ['date', 'daterange'].includes(filter.type)) {
                    // N.B. Filtering is done in DataTables plugin
                    return;
                }

                const column = this.$options.dataTable.column(attribute + ':name');
                if (!value || value.length === 0) {
                    column.search('', false);
                } else if (Array.isArray(value)) {
                    const regex = '^\\s*(' + value.map(x => $.fn.dataTable.util.escapeRegex(x)).join('|') + ')\\s*$';
                    column.search(regex, true);
                } else if (filter && !filter.regex) {
                    column.search(value, false);
                } else {
                    column.search('^\\s*' + $.fn.dataTable.util.escapeRegex(value) + '\\s*$', true);
                }
            },

            updateShowOnlyActive(showOnlyActive) {
                const column = this.$options.dataTable.column('active:name');

                if (showOnlyActive) {
                    if (this.config.showOnlyActiveShouldSearchNegative) {
                        column.search('');
                    } else {
                        column.search(true, true, false);
                    }
                } else {
                    if (this.config.showOnlyActiveShouldSearchNegative) {
                        column.search(false, true, false);
                    } else {
                        column.search('');
                    }
                }
            },

            searchByFieldValue(fieldValueQuery) {
                const filter = this.config.filters.find(filter => filter.type === 'field_value');

                if (!filter) {
                    return;
                }

                // Reset all field value queries as we can only search for one field at a time
                filter.options.forEach(option => {
                    this.$options.dataTable
                        .column(option.attribute + ':name')
                        .search('');
                });

                if (fieldValueQuery !== null && fieldValueQuery.value !== null) {
                    this.$options.dataTable
                        .column(fieldValueQuery.field + ':name')
                        .search(fieldValueQuery.value, true, false);
                }
            },

            selectPrev(deselectFirst = true) {
                const selectedRows = this.$options.$table.children('tbody').children('tr.active');
                if (this.$options.dataTable.rows({ selected: true }).data().length > 0 || deselectFirst) {
                    this.deselectAllRows();
                }

                if (selectedRows.length === 1) {
                    const previousRow = $(selectedRows[0]).prev()[0];
                    if (previousRow) {
                        this.$options.dataTable.row($(previousRow)).select();
                    }
                }
            },

            selectNext(deselectFirst = true) {
                const selectedRows = this.$options.$table.children('tbody').children('tr.active');
                if (this.$options.dataTable.rows({ selected: true }).data().length > 0 || deselectFirst) {
                    this.deselectAllRows();
                }

                if (selectedRows.length === 1) {
                    const nextRow = $(selectedRows[0]).next()[0];
                    if (nextRow) {
                        this.$options.dataTable.row($(nextRow)).select();
                    }
                }
            },

            deselectAllRows() {
                this.$options.dataTable.rows({ selected: true }).deselect();
            },

            createdRow(row, data, dataIndex, cells) {
                if (this.config.activeTitles) {
                    if (data[this.config.activeTitleColumn]) {
                        $(row).addClass(this.config.activeTitleRowClass);
                    }
                }

                // The column widths aren't properly inherited from the thead
                // when the headers are hidden, so we explicitly set them.
                if (this.config.hideHeaders) {
                    this.config.columns.forEach((column, index) => {
                        if (column.options.width) {
                            cells[this.correctColumnIndex(index)].style.width = column.options.width;
                        }
                    });
                }

                if (this.config.subTables) {
                    if (!this.config.subTableUrl && dataGet(data, this.config.subTableColumn, []).length === 0) {
                        $(row).find('.js-overview-sub-table-button').prop('disabled', true);
                    }
                }
            },

            rowGroupStartRender(rows, group) {
                const rowData = rows.data()[0];
                const $tr = $('<tr/>');
                $tr.data('is_grouping_row', true);
                $tr.data('grouping_row_data', rowData);

                if (this.config.groupsCheckable) {
                    $tr.append(`<td class="overview__column overview__column--checkbox">
                                    <div class="form-checkbox overview-checkbox">
                                        <input id="overview-group-${S(group).slugify()}" type="checkbox" class="form-checkbox__input">
                                        <label for="overview-group-${S(group).slugify()}" class="form-checkbox__label form-checkbox__label--no-content"></label>
                                    </div>
                                </td>`);
                }

                if (this.config.groupsCollapsable) {
                    $tr.append(`<td class="overview__column overview__column--group-toggle">
                                    <button type="button" class="button button--icon button--text js-overview-group-button" data-open="false">
                                        ${iconRenderer('caret-right', 'button__icon')}
                                    </button>
                                </td>`);
                }

                $tr.append(
                    group instanceof $ ? group : $('<td class="overview__column overview__column--group"/>')
                        .attr('colspan', this.getGroupColspan())
                        .html(this.config.groupingAction && rowData[this.config.groupingValue]
                            ? ` <div class="overview__column_group-wrapper">
                                    <span>${group}</span>
                                    <button class="button button--light button--low overview__column_group-wrapper-action"
                                        data-grouping-action-value="${this.config.groupingValue ? rowData[this.config.groupingValue] : group}">
                                            ${this.config.groupingAction.label}
                                        </button>
                                </div>` : group)
                );

                if (this.config.showContextMenuButton && !this.config.groupingAction) {
                    const $actions = $('<td class="overview__column overview__column--actions-button"/>');

                    if (this.config.groupingContextMenuItems.length) {
                        const icon = iconRenderer('dots', 'button__icon');

                        $actions.append(`<button type="button" class="button button--icon button--secondary js-overview-context-menu-button">${icon}</button>`);
                    }

                    $tr.append($actions);
                }

                const color = this.config.groupingColor ? rowData[this.config.groupingColor] : null;
                if (color) {
                    $tr.find('td').css('background-color', color);
                }

                if (this.config.sortable) {
                    $tr.data('sorting-value', 0);
                }

                if (this.config.groupingValue) {
                    $tr.add(rows.nodes()).data('grouping-value', rowData[this.config.groupingValue]);
                }

                return $tr;
            },

            getGroupColspan() {
                const invisibleColumns = this.config.columns
                    .filter(column => column.options.visible === false)
                    .length;

                let colspan = this.$options.dataTable.columns()[0].length - invisibleColumns;

                if (this.config.groupsCheckable) {
                    --colspan;
                }

                if (this.config.groupsCollapsable) {
                    --colspan;
                }

                if (this.config.showContextMenuButton && !this.config.groupingAction) {
                    --colspan;
                }

                return colspan;
            },

            handleResize() {
                if ($(`#${this.$attrs.id}`).is(':visible')) {
                    this.updateScrollY();
                    this.$options.dataTable.columns.adjust();
                }
            },

            getRowData() {
                return this.$options.dataTable ? this.$options.dataTable.rows({ search: 'applied' }).data().toArray() : [];
            },

            getRowCount() {
                return this.getRowData().length;
            },

            setUrl(url) {
                this.$options.dataTable.ajax.url(url);
            },

            toggleExpandGroups() {
                const groups = this.$options.$table.find('tr.js-group');

                if (!groups.length) {
                    return;
                }

                const isOpen = this.openedRows.length;

                this.openedRows = isOpen ? [] : groups.map((index, group) => $(group)
                    .nextUntil('tr.js-group')
                    .get()
                    .map(row => this.$options.dataTable.row(row).id())
                ).toArray().flat();

                groups.each((index, group) => {
                    const $group = $(group);
                    const $button = $group.find('.js-overview-group-button');

                    if (isOpen) {
                        $group.nextUntil('tr.js-group').hide();
                        $button.data('open', false);
                        $button.removeClass('button--rotate-90');
                    } else {
                        $group.nextUntil('tr.js-group').show();
                        $button.data('open', true);
                        $button.addClass('button--rotate-90');
                    }
                });
            },
        },

        watch: {
            openedRows() {
                this.$emit('opened-rows', this.openedRows);
            },
        },

        $table: null,

        dataTable: null,
    };
</script>
